import React from 'react';

import { ConfirmProvider } from 'material-ui-confirm';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from 'context/AuthContext';
import { store } from 'store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeLoader from './theme-loader/ThemeLoader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfirmProvider>
      <Provider store={store}>
        <ThemeLoader>
          <BrowserRouter basename="/">
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </ThemeLoader>
      </Provider>
    </ConfirmProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
