import { queryParamsBuilder, updateCacheData } from 'utils/commonFunctions';

import { createApiInstance } from './createApiInstance';

export const extendedApi = createApiInstance.injectEndpoints({
  tagTypes: ['UpdateCart', 'cartInfo', 'UpdateProjects'],
  endpoints: (build) => ({
    createOrder: build.mutation({
      query(body) {
        return {
          url: '/create-order',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        'cartInfo',
        'UpdateProjects',
        'UpdateCart',
        'GetSalesOrder',
      ],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getOrders',
              dispatch,
              extendedApi,
            },
            (draft) => {
              draft.counts += 1;
              if (draft.data.length < 10) {
                draft.data.unshift(data.data);
              }
            }
          );
          updateCacheData(
            { state: getState(), method: 'getCartInfo', dispatch, extendedApi },
            (draft) => {
              Object.keys(draft)?.forEach((key) => {
                delete draft[key];
              });
              // draft = {};
            }
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateCartItem: build.mutation({
      query(body) {
        return {
          url: '/update-cart-item',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['cartInfo'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getUserCart',
              dispatch,
              extendedApi,
            },
            (draft) => {
              const dataIndex = draft.findIndex((item) => item.id === data.id);
              if (dataIndex > -1) {
                draft[dataIndex] = data;
              }
            }
          );
        } catch (error) {
          console.error('Update Cart Item Error', error);
        }
      },
    }),
    addToCart: build.mutation({
      query(body) {
        return {
          url: '/add-to-cart',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['cartInfo', 'UpdateProjects', 'UpdateCartByProject'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getUserCart',
              dispatch,
              extendedApi,
            },
            (draft) => {
              if (data.type === 'added') {
                draft.push(data.data);
              } else if (data.type === 'updated') {
                const dataIndex = draft.findIndex((item) => {
                  return item.id === data.data.id;
                });
                if (dataIndex > -1) {
                  draft[dataIndex] = data.data;
                }
              }
            }
          );
        } catch (error) {
          console.error('Add to Cart Error', error);
        }
      },
    }),
    addCartInfo: build.mutation({
      query: (body) => {
        return {
          url: '/add-cart-info',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getCartInfo',
              dispatch,
              extendedApi,
            },
            (draft) => {
              Object.assign(draft, {
                ...JSON.parse(JSON.stringify(draft)),
                ...data,
              });
            }
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    addProjectToCart: build.mutation({
      invalidatesTags: ['UpdateProjects', 'GetSalesOrder'],
      query: (body) => {
        return {
          url: '/add-project-to-cart',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getCartInfo',
              dispatch,
              extendedApi,
            },
            (draft) => {
              Object.assign(draft, {
                ...JSON.parse(JSON.stringify(draft)),
                ...data,
              });
            }
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    createProject: build.mutation({
      query: (body) => {
        return {
          url: '/create-project',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UpdateProjects'],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getUserProjects',
              dispatch,
              extendedApi,
            },
            (draft) => {
              Object.assign(draft, {
                ...JSON.parse(JSON.stringify(draft)),
                ...data?.data,
              });
            }
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getCartInfo: build.query({
      query: ({ userId }) => {
        return {
          url: '/get-cart-info',
          method: 'GET',
          params: {
            userId,
          },
        };
      },
      providesTags: ['cartInfo'],
    }),
    getAllMaterials: build.query({
      query: () => '/get-all-materials',
      transformResponse: (res) => {
        return res;
      },
    }),
    updateAllCart: build.mutation({
      query(body) {
        return {
          url: '/update-all-cart',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UpdateProjects', 'cartInfo'],
    }),
    updateProject: build.mutation({
      query(body) {
        return {
          url: '/update-project',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UpdateProjects'],
    }),
    getFinishMaterial: build.query({
      query: () => {
        return {
          url: '/finish-material',
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    getCabinetsByCategory: build.query({
      query: () => '/cabinets-by-category',
      transformResponse: (res) => {
        return res;
      },
    }),
    getCabinetsInCategory: build.query({
      query: (query) => `/cabinets-in-category${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getSearchedCabinets: build.query({
      query: (query) => `/cabinets${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getCabinetById: build.query({
      query: (query) => {
        return `/cabinets/${query.id}`;
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    getAddressData: build.query({
      query: () => '/get-address-data',
      transformResponse: (res) => {
        return res;
      },
    }),
    getCity: build.query({
      query: (search) => ({
        url: '/get-cities',
        params: { search },
      }),
    }),
    getStates: build.query({
      query: () => ({
        url: '/get-states',
      }),
    }),
    getPostalAddress: build.query({
      query: (zipCode) => {
        return {
          url: '/get-postal-address',
          params: { zipCode },
        };
      },
    }),
    // getPromoCodeData: build.query({
    //   query: (query) => `/get-promo-code${queryParamsBuilder(query)}`,
    //   transformResponse: (res) => {
    //     return res;
    //   },
    // }),
    getOrders: build.query({
      query: (query) => `/get-orders${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getOrderItems: build.query({
      query: (query) => `/get-order/${query}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getUserCart: build.query({
      providesTags: ['UpdateCart'],
      query: () => '/get-user-cart',
      transformResponse: (res) => {
        return res.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      },
    }),
    getUserCartByProject: build.query({
      providesTags: ['UpdateCartByProject'],
      query: (query) => `/get-user-cart-by-project${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      },
    }),
    getUserProjects: build.query({
      providesTags: ['UpdateProjects'],
      query: () => '/get-user-projects',
      transformResponse: (res) => {
        return res.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      },
    }),
    getCartProjects: build.query({
      providesTags: ['GetSalesOrder'],
      query: () => '/get-cart-projects',
      transformResponse: (res) => {
        res.projectData.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
        return res;
      },
    }),
    getUserProjectsById: build.query({
      query: (query) => `/get-user-projects-by-id${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    // getTotalOrderCost: build.mutation({
    //   query(body) {
    //     return {
    //       url: '/get-total-order-cost',
    //       method: 'POST',
    //       body,
    //     };
    //   },
    // }),
    getSelectedMaterialData: build.mutation({
      query(body) {
        return {
          url: '/get-selected-material-data',
          method: 'POST',
          body,
        };
      },
    }),
    removeCartItem: build.mutation({
      query(id) {
        return {
          url: `/cart/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['cartInfo', 'UpdateCartByProject', 'UpdateProjects'],
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          updateCacheData(
            {
              state: getState(),
              method: 'getUserCart',
              dispatch,
              extendedApi,
            },
            (draft) => {
              return draft.filter((item) => item.id !== args);
            }
          );
        } catch (error) {
          console.error('Remove Cart Item Error', error);
        }
      },
    }),
    removeProjectFromSalesOrder: build.mutation({
      query(id) {
        return {
          url: `/remove-project-from-cart/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        'GetSalesOrder',
        'UpdateCartByProject',
        'UpdateProjects',
      ],
    }),
    deleteProject: build.mutation({
      query(id) {
        return {
          url: `/project/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        'UpdateProjects',
        'UpdateCartByProject',
        'GetSalesOrder',
      ],
    }),
    // getCabinetsByCatalog: build.query({
    //   query: (query) => `/get-cabinets-by-catalog${queryParamsBuilder(query)}`,
    //   transformResponse: (res) => {
    //     return res;
    //   },
    // }),
    // downloadOrder: build.query({
    //   query: (query) => ({
    //     url: `/download-order/${query}`,
    //     responseType: 'blob',
    //   }),
    // }),
    downloadReviewOrder: build.query({
      query: (params) => {
        return {
          url: '/download-review-order',
          method: 'GET',
          params,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    uploadFile: build.mutation({
      query: (formData) => ({
        url: '/upload-file',
        method: 'POST',
        body: formData,
      }),
    }),
    getUploadedFile: build.query({
      query: (fileName) => ({
        url: `/get-uploaded-file/${fileName}`,
        method: 'GET',
      }),
    }),
    deleteUploadedFile: build.mutation({
      query: (fileName) => ({
        url: `/delete-uploaded-file/${fileName}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateOrderMutation,
  useAddToCartMutation,
  useAddCartInfoMutation,
  useGetCartInfoQuery,
  useGetAllMaterialsQuery,
  useUpdateAllCartMutation,
  useAddProjectToCartMutation,
  useGetFinishMaterialQuery,
  useGetCabinetsByCategoryQuery,
  useGetCabinetsInCategoryQuery,
  useGetAddressDataQuery,
  useGetCityQuery,
  useGetStatesQuery,
  useLazyGetPostalAddressQuery,
  // useGetPromoCodeDataQuery,
  // useGetTotalOrderCostMutation,
  useGetSelectedMaterialDataMutation,
  useGetOrdersQuery,
  useGetOrderItemsQuery,
  // useGetCabinetsByCatalogQuery,
  // useLazyDownloadOrderQuery,
  useGetUserCartQuery,
  useGetUserProjectsQuery,
  useGetCartProjectsQuery,
  useGetCabinetByIdQuery,
  useRemoveProjectFromSalesOrderMutation,
  useGetSearchedCabinetsQuery,
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,
  useLazyDownloadReviewOrderQuery,
  useUploadFileMutation,
  useLazyGetUploadedFileQuery,
  useDeleteUploadedFileMutation,
  useCreateProjectMutation,
  useGetUserCartByProjectQuery,
  useLazyGetUserCartByProjectQuery,
  useGetUserProjectsByIdQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = extendedApi;
